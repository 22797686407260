export const operationEvents = `
  query GetEventTags {
    eventTags: event_tags {
      name
      id
      description
    }
  }

  query GetEventGategories {
    eventGategories: event_categories {
      description
      id
      name
    }
  }

  query GetFeaturedEvents($limit: Int!) {
    events(order_by: {date: desc}, limit: $limit) {
      date
      image
      imageOg
      id
      game {
        banner
        name
        slug
        thumbnail
      }
      eventTypeId
      eventCategoryId
      eventTagId
      gameId
      link
      isActive
      profileType {
        name
        slug
        color
        category
        icon
      }
      participationTypeId
      title
      slug
      eventType {
        active
        name
      }
      description
      isParent
      parentEventId
      userId
      owner {
        name
        username
        profileImage
      }
      isFeature
      eventTag {
        name
        id
      }
      eventCategory {
        id
        name
      }
    }
  }  

  query GetEvents($limit: Int!, $offset: Int!, $where: events_bool_exp = {date: {_gte: "now()"}}) {
    events(order_by: {date: desc}, limit: $limit, offset: $offset, where: $where) {
      date
      image
      imageOg
      id
      game {
        banner
        name
        slug
        thumbnail
      }
      eventTypeId
      eventCategoryId
      eventTagId
      gameId
      link
      isActive
      profileType {
        name
        slug
        color
        category
        icon
      }
      participationTypeId
      title
      slug
      eventType {
        active
        name
      }
      description
      isParent
      parentEventId
      userId
      owner {
        name
        username
        profileImage
      }
      isFeature
      eventTag {
        name
        id
      }
      eventCategory {
        id
        name
      }
    }
    pagination: events_aggregate(where: $where) {
      data: aggregate {
        total: count(columns: userId)
      }
    }
  }  

  query GetEventBySlug($slug: String = "", $userId: Int = 10) {
    events(where: {slug: {_eq: $slug}}) {
      date
      image
      imageOg
      id
      game {
        banner
        name
        slug
        thumbnail
      }
      eventTypeId
      eventCategoryId
      eventTagId
      gameId
      link
      isActive
      profileType {
        name
        slug
        color
        category
        icon
      }
      participationTypeId
      title
      eventType {
        active
        name
      }
      description
      isParent
      userId
      owner {
        id
        name
        username
        profileImage
      }
      isFeature
      eventType {
        active
        name
      }
      eventTag {
        name
        id
      }
      eventCategory {
        id
        name
      }
      userParticipant: eventParticipants_aggregate(where: {userId: {_eq: $userId}}) {
        data: aggregate {
          total: count(columns: userId)
        }
      }
    }
  }  

  query GetEventParticipants($limit: Int = 12, $offset: Int = 0, $userId: Int!, $slug: String = "") {
    participants: event_participants(where: {event: {slug: {_eq: $slug}}}, limit: $limit, offset: $offset, order_by: {user: {id: desc, isVerified: desc, isBetaUser: desc}}) {
      user {
        lastname
        name
        username
        isWorker
        isVerified
        isBetaUser
        isActive
        isPartner
        isSponsored
        isFeatured
        id
        email
        country {
          name
          id
        }
        birthday
        bannerImage
        about
        aboutRich
        online
        profileImage
        lookingForJob
        showBirthday
        onboarded
        profileTypes(where: {isActive: {_eq: true}}) {
          profileType {
            name
            slug
            id
            category
            color
            icon
          }
          isActive
          order
        }
        socials(order_by: {order: asc}) {
          socialAccount {
            id
            name
            isActive
          }
          link
        }
        userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
          total: aggregate {
            count(columns: userId)
          }
        }
      }
    }
    pagination: event_participants_aggregate(where: {event: {slug: {_eq: $slug}}}) {
      data: aggregate {
        total: count(columns: eventId)
      }
    }
  } 

  mutation JoinEvent($object: event_participants_insert_input = {}) {
    joinedEvent: insert_event_participants_one(object: $object) {
      id
      createdAt
      eventId
      userId
    }
  }
`