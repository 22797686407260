"use client";

import React, { FC } from "react";
import Image from 'next/image';
import Link from 'next/link';
import classNames from 'classnames';
import Anchor from "@apg.gg/core/lib/Link";
import TwitterIcon from '@apg.gg/icons/lib/TwitterIcon';
import InstagramIcon from '@apg.gg/icons/lib/InstagramIcon';
import DiscordIcon from '@apg.gg/icons/lib/DiscordIcon';
import { useTranslations } from "next-intl";
import { useParams } from "next/navigation";

const Footer: FC = () => {
  const t = useTranslations('Common');
  const { locale } = useParams();

  return (
    <div className="flex flex-wrap bg-black mt-auto">
      <div
        className={classNames(
          'flex flex-col items-center justify-center relative w-full mt-16 mb-12 gap-4'
        )}
      >
        <Image src="/assets/resources/isotipo-white.png" alt='APG.gg' width={149} height={56} />
        <p className="text-black-400 text-body-lg text-center">{t('footer-menu.connect-with-us')}</p>

        <ul className="flex flex-col md:flex-row gap-4 my-4 text-center">
          <li>
            <Link href={`/${locale}`} className="hover:text-aqua-100">{t('footer-menu.home')}</Link>
          </li>
          <li>
            <Link href="https://drive.google.com/drive/folders/13GSPrkfMMcM3d0gYveTV_pZS3CqjrQ6r?usp=sharing" target="_blank" className="hover:text-aqua-100">{t('footer-menu.branding')}</Link>
          </li>
          <li>
            <Link href={`/${locale}/terms-and-conditions`} className="hover:text-aqua-100">{t('footer-menu.terms-and-conditions')}</Link>
          </li>
          <li>
            <Link href={`/${locale}/privacy-policy`} className="hover:text-aqua-100">{t('footer-menu.privacy-policy')}</Link>
          </li>
        </ul>

        <div className="social-box flex gap-4">
          <Anchor
            type="primary"
            href="https://www.twitter.com/apg_club"
            target="_blank"
            icon={<TwitterIcon />}
          >
          </Anchor>
          <Anchor
            type="primary"
            href="https://www.instagram.com/apg_club"
            target="_blank"
            icon={<InstagramIcon  />}
          >
          </Anchor>
          <Anchor
            type="primary"
            href="https://www.discord.gg/6ja3zj9XyR"
            target="_blank"
            icon={<DiscordIcon />}
          >
          </Anchor>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
