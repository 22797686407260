"use client";

import { ErrorBoundary } from "react-error-boundary";
import HeroSectionClient from "./hero-section";
import ErrorSectionFallback from "../ErrorSection";
import LatestUsersClient from "./latest-users";
import SectionAboutClient from "./section-about";
import SectionEventsClient from "./section-events";
import SectionProfileTypesClient from "./section-profile-types";

export default function HomeClient({
  userId
}: {
  userId: number;
}) {
  return (
    <>
      <HeroSectionClient />

      <ErrorBoundary 
        FallbackComponent={({ error }) => (
          <ErrorSectionFallback error={error} section="about-client" />
        )}
      >
        <SectionAboutClient userId={userId} />
      </ErrorBoundary>

      <ErrorBoundary 
        FallbackComponent={({ error }) => (
          <ErrorSectionFallback error={error} section="events-client" />
        )}
      >
        <SectionEventsClient />
      </ErrorBoundary>

      <ErrorBoundary 
        FallbackComponent={({ error }) => (
          <ErrorSectionFallback error={error} section="profile-types-client" />
        )}
      >
        <SectionProfileTypesClient userId={userId} />
      </ErrorBoundary>

      <ErrorBoundary 
        FallbackComponent={({ error }) => (
          <ErrorSectionFallback error={error} section="latest-users-client" />
        )}
      >
        <LatestUsersClient userId={userId} />
      </ErrorBoundary>
    </>
  )
}