"use client";

import { useTranslations } from "next-intl";
import { cn } from "@apg.gg/core/lib/cn";
import Image from 'next/image';
import { useRouter } from "next-nprogress-bar";
import Button from "@apg.gg/core/lib/Button";
import { useParams } from "next/navigation";
import { Suspense } from "react";
import PopularGames from "./popular-games";

export default function HeroSectionClient() {
  const router = useRouter();
  const { locale } = useParams();
  const t = useTranslations('Home');

  return (
    <section id="home" className={
      cn(
        "bg-cover bg-no-repeat bg-center pb-16 relative"
      )
    } style={{ backgroundImage: 'url(/assets/resources/home-bg-main@2x.png)' }}>
      <div className="container h-full lg:max-w-full">
        <div className="flex flex-col items-center gap-4 relative pt-10 h-full">
          <Image src="/assets/resources/isotipo-white.png" alt='APG.gg' width={162} height={61} />

          <div className="flex flex-col items-center gap-4 justify-center">
            <h1 className="uppercase text-display-sm sm:text-display-lg text-center max-w-3xl text-balance">{t("the-future-of-latam-gaming-is-here")}</h1>
            <p className="text-2xl font-source-sans-pro text-center text-balance">{t("the-platform-revolutionizing-the-latin-american-gaming-community-create-your-profile-centralize-your-achievements-and-events-share-your-talent-connect-with-partners-and-sponsors-discover-new-titles-and-make-a-difference")}</p>

            <Button
              type="primary"
              className="px-8 py-4 text-title-lg"
              onClick={() => {
                router.push(`/${locale}/auth/login`);
              }}
            >
             {t("join-now-and-write-the-future")}
            </Button>
          </div>

          <Suspense fallback={null}>
            <PopularGames />
          </Suspense>
        </div>
      </div>

      <div className="absolute hero-gradient-900 -bottom-8 left-0 w-full h-40"></div>
    </section>
  )
}