"use client";

import { useTranslations } from "next-intl";
import { ProfileType } from "@/domain/general.interface";
import { cn } from "@apg.gg/core/lib/cn";
import Spinner from "@/components/ui/Spinner";
import Image from "next/image";
import useWindowSize from "@apg.gg/core/lib/useWindowSize";
import { useProfileTypes } from "@/hooks/useGeneral";
import { AnimatePresence, motion } from "framer-motion";
import Tag from "@apg.gg/core/lib/Tag";

export default function SectionProfileTypesClient({
  userId
}: {
  userId: number;
}) {
  const { isLtMd } = useWindowSize();
  const t = useTranslations('Home');

  const { data: profileTypes, isLoading } = useProfileTypes();

  return (
    <section id="place" className="w-full py-8 relative">
      <div className="container-home">
        {isLoading ? (
          <div className={cn(
            "flex items-center justify-center w-full h-96"
          )}>
            <Spinner />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-wrap px-20 py-8 gap-2 content-end items-end justify-end">
              <AnimatePresence>
                {profileTypes && profileTypes.map((profileType: ProfileType) => {
                  return (
                    <motion.div
                      style={{ overflow: "hidden" }}
                      key={profileType?.id}
                      role='button'
                      layout
                      initial={{ transform: "scale(0)" }}
                      animate={{ transform: "scale(1)" }}
                      exit={{ transform: "scale(0)" }}
                    >
                      <Tag
                        type={profileType?.color}
                        icon={<Image src={`/assets/icons/${profileType?.icon}/big.png`} alt={profileType?.name || ''} width={16} height={16} />}
                      >
                        {profileType?.name}
                      </Tag>
                    </motion.div>
                  )
                })}
              </AnimatePresence>
            </div>
            <div className="flex flex-col items-start gap-4 justify-center px-10">
              <h1 className="uppercase text-headline-lg sm:text-display-sm text-center md:text-left max-w-5xl text-balance">{t('discover-companions-talents-and-connections-your-gamer-network-starts-here')}</h1>
              <p className="text-2xl font-source-sans-pro text-center md:text-left text-balance">{t('are-you-a-gamer-find-your-space-share-your-passion-explore-new-facets-connect-with-the-community-join-now')}</p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}