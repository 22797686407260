
import { useQuery } from "@tanstack/react-query"
import { GeneralEndpoints } from "@/graph/general.endpoints";

export const useCountries = () => {
  const countriesQuery = useQuery(
    {
      queryKey: ['apg-countries'],
      queryFn: () => GeneralEndpoints.getCountries(),
      staleTime: 1000 * 60 * 10
    }
  )

  return countriesQuery
}

export const useSocials = () => {
  const socialsQuery = useQuery(
    {
      queryKey: ['apg-socials'],
      queryFn: () => GeneralEndpoints.getSocials(),
      staleTime: 1000 * 60 * 10
    }
  )

  return socialsQuery
}

export const useLoginProviders = () => {
  const socialsQuery = useQuery(
    {
      queryKey: ['apg-login-providers'],
      queryFn: () => GeneralEndpoints.getLoginProviders(),
      staleTime: 1000 * 60 * 10
    }
  )

  return socialsQuery
}

export const useProfileTypes = () => {
  const profileTypes = useQuery(
    {
      queryKey: ['apg-profile-types'],
      queryFn: () => GeneralEndpoints.getProfileTypes(),
      staleTime: 1000 * 60 * 10
    }
  )

  return profileTypes
}

export const useProfileTypeOptions = () => {
  const profileTypes = useQuery(
    {
      queryKey: ['apg-profile-types-options'],
      queryFn: () => GeneralEndpoints.getProfileTypeOptions(),
      staleTime: 1000 * 60 * 10
    }
  )

  return profileTypes
}

export const useGameOptions = () => {
  const gameOptions = useQuery({
    queryKey: ['apg-games'],
    queryFn: () => GeneralEndpoints.getGamesLikeOptions(),
    staleTime: 1000 * 60 * 10
  })
  
  return gameOptions
}