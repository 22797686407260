import { menuItems } from '@/layouts/customMenus'
import { ItemProps } from '@apg.gg/core/lib/Sidebar'
import { create } from 'zustand'

type Store = {
  items: ItemProps[],
  locale: string,
  setLocale: (locale: string) => void,
  addItem: (newItem: ItemProps) => void,
  resetItems: () => void
}

const useSidebarStore = create<Store>((set) => {
  const initialItems = [...menuItems('es')];

  return {
    items: initialItems,
    locale: 'es',
    setLocale: (locale) => set({ 
      locale,
      items: [...menuItems(locale)]
    }),
    addItem: (newItem) => set((state) => {
      let newItems = state.items;
      if (newItems.length === 5) {
        newItems = newItems.slice(1);
      }
      return { items: [newItem, ...newItems] };
    }),
    resetItems: () => set(() => ({ items: initialItems })),
  }
});

export default useSidebarStore;