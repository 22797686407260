"use client";

import classNames from "classnames";
import Image from 'next/image';
import { useSuspenseQuery } from "@tanstack/react-query";
import { GeneralEndpoints } from "@/graph/general.endpoints";
import Link from "next/link";
import { useParams } from "next/navigation";

export default function PopularGames() {
  const { locale } = useParams();
  const { data: games } = useSuspenseQuery(
    {
      queryKey: ['apg-home-games'],
      queryFn: () => GeneralEndpoints.getGames({ sort: 'users-quantity', limit: 5 }),
      staleTime: 1000 * 60 * 10
    }
  )

  return (
    <div className="flex w-full items-start justify-center gap-2 mt-8">
      {games && games.map((game, index) => {
        const imageClasses = classNames('rounded-2xl', {
          'mt-8': index === 1 || index === 3,
          'mt-16': index === 2
        });

        return (
          <Link href={`/${locale}/games/${game.slug}`} key={`home-game--${index}`}>
            <Image
              src={game.thumbnail}
              alt={game.name}
              width={260}
              height={280}
              className={imageClasses}
            />
          </Link>
        );
      })}
    </div>
  )
}