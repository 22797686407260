"use client";

import useSidebarStore from "@/store/sidebar.store";
import { useEffect } from "react";

export default function ResetSidebarItems() {
  const { resetItems } = useSidebarStore();

  useEffect(() => {
    resetItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    </>
  )
}