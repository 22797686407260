"use client";

import { useQuery } from "@tanstack/react-query";
import { GeneralEndpoints } from "@/graph/general.endpoints";
import { useTranslations } from "next-intl";
import { UsersProfileTypeElement } from "@/domain/general.interface";
import { cn } from "@apg.gg/core/lib/cn";
import Spinner from "@/components/ui/Spinner";
import CardStack from "@/components/CardStack";
import CardProfileFollow from "@/components/ui/CardProfileFollow";
import useWindowSize from "@apg.gg/core/lib/useWindowSize";

export default function SectionAboutClient({
  userId
}: {
  userId: number;
}) {
  const { isLtMd } = useWindowSize();
  const t = useTranslations('Home');

  const { data: users, isLoading } = useQuery({
    queryKey: ['apg-home-about-section-users'],
    queryFn: async () => await GeneralEndpoints.getFeatureUsers({
      userLoggedId: userId,
      limit: 5
    })
  })

  return (
    <section id="about" className="w-full py-8 relative">
      <div className="container-home">
        {isLoading ? (
          <div className={cn(
            "flex items-center justify-center w-full h-96"
          )}>
            <Spinner />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex relative z-10 overflow-hidden items-center justify-end">
              <CardStack 
                cards={users as UsersProfileTypeElement[]} 
                position={isLtMd ? "top" : "left"}
                renderCard={(item: UsersProfileTypeElement) => <CardProfileFollow user={item} className="w-[348px]" />}
              />
            </div>
            <div className="flex flex-col items-center gap-4 justify-center px-4 md:px-10">
              <h1 className="uppercase text-headline-lg sm:text-display-sm text-center md:text-left max-w-5xl text-balance">{t("your-home-in-the-latam-gaming-world")}</h1>
              <p className="text-2xl font-source-sans-pro text-center md:text-left text-balance">
                {t("apg-is-the-hub-for-latin-american-gamers-create-your-profile-centralize-your-achievements-events-and-games-share-your-talent-and-become-part-of-the-community")}
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}