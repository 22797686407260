"use client";

import Button from "@apg.gg/core/lib/Button";
import { useTranslations } from "next-intl";
import { useErrorBoundary } from "react-error-boundary";
import RichText from './RichText';
import useGlobalStore from "@/store/global.store";
import { useEffect } from "react";

export default function ErrorCardProfileFallback({ error, section }: any) {
  const t = useTranslations('Common');
  const { resetBoundary } = useErrorBoundary();
  const { errorRetries, setErrorRetries } = useGlobalStore();

  useEffect(() => {
    if (!errorRetries[section as keyof typeof errorRetries]) {
      setErrorRetries({
        ...errorRetries,
        [section]: 0
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col bg-black-900 px-4 py-8 gap-3 rounded-2xl items-center h-full">
      {/* Content section */}
      <div className="flex flex-col justify-center w-full items-center gap-4 h-full">
        <h3 className="text-headline-sm">
          {t('error-loading-card-profile')}
        </h3>
        <p className="text-center">
          {t('error-loading-card-profile-description')}
        </p>

        {errorRetries[section as keyof typeof errorRetries] < 2 ? (
          <Button fontSize="sm" type="outline" onClick={() => {
            setErrorRetries({
              ...errorRetries,
              [section]: errorRetries[section as keyof typeof errorRetries] + 1
            });
            resetBoundary();
          }}>
            {t('error-try-again-label')}
          </Button>
        ) : (
          <p className="text-sm text-center">
            <RichText>
              {
                t.rich('error-report-to-discord-description', {
                  link: (chunks) => <a href="https://discord.com/channels/1037732149832466463/1157505835136716883" target="_blank" className="text-aqua">{chunks}</a>
                })
              }
            </RichText>
          </p>
        )}
      </div>
    </div>
  );
}