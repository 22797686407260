import { SubItemProps } from "@apg.gg/core/lib/Sidebar"
import Link from "next/link"
import HomeIcon from '@apg.gg/icons/lib/HomeIcon';
import SearchIcon from '@apg.gg/icons/lib/SearchIcon';
import GroupIcon from '@apg.gg/icons/lib/GroupIcon';
import JoytickIcon from '@apg.gg/icons/lib/JoytickIcon';
import SettingsIcon from '@apg.gg/icons/lib/SettingsIcon';
import EditIcon from "@apg.gg/icons/lib/EditIcon";
import LinkIcon from "@apg.gg/icons/lib/LinkIcon";
import VerifiedIcon from '@apg.gg/icons/lib/VerifiedIcon';
import GameControlIcon from '@apg.gg/icons/lib/GameControlIcon';
import SocialIcon from '@apg.gg/icons/lib/SocialIcon';
import StarsIcon from '@apg.gg/icons/lib/StarsIcon';
import LogoutIcon from '@apg.gg/icons/lib/LogoutIcon';
import CalendarIcon from '@apg.gg/icons/lib/CalendarIcon';
import PersonIcon from "@apg.gg/icons/lib/PersonIcon";
import logout from "@/lib/utils/logout";
import ConstructionIcon from "@apg.gg/icons/lib/ConstructionIcon";
import BarChartIcon from "@apg.gg/icons/lib/BarChartIcon";

export const menuItems = (locale: string) => [
  {
    key: "",
    name: "Home",
    icon: <HomeIcon />,
    iconHoverColor: "blue-300",
    bgColor: "black-600",
    activeBgColor: "blue",
    href: `/${locale}/feed`,
    linkComponent: Link
  },
  {
    key: "search", 
    name: "Search",
    icon: <SearchIcon />,
    iconHoverColor: "aqua-100",
    bgColor: "black-600",
    activeBgColor: "aqua"
  },
  {
    key: "tools", 
    name: "Tools",
    icon: <ConstructionIcon />,
    iconHoverColor: "fuscia-100",
    bgColor: "black-600",
    activeBgColor: "fuscia",
    href: `${process.env.NEXT_PUBLIC_TOOL_URL}/${locale}`, 
    linkComponent: Link
  },
  {
    key: "/community", 
    name: "Community",
    icon: <GroupIcon />,
    iconHoverColor: "green-100",
    bgColor: "black-600",
    activeBgColor: "green",
    href: `/${locale}/community`,
    linkComponent: Link
  },
  {
    key: "/games", 
    name: "Games",
    icon: <JoytickIcon />,
    iconHoverColor: "yellow-200",
    bgColor: "black-600",
    activeBgColor: "yellow",
    href: `/${locale}/games`,
    linkComponent: Link
  },
  {
    key: "/events", 
    name: "Events",
    icon: <CalendarIcon />,
    iconHoverColor: "red-100",
    bgColor: "black-600",
    activeBgColor: "red",
    href: `/${locale}/events`,
    linkComponent: Link
  }
]

interface SubItemPropsCustom extends SubItemProps {
  isNew?: boolean
}

export const subItemsAccount = (t: any): SubItemPropsCustom[] => [
  {
    key: "account",
    name: t('sidebar.settings'),
    href: "account",
    type: "link",
    icon: <SettingsIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link
  },
  {
    key: "editor",
    name: t('sidebar.edit-profile'),
    href: "editor",
    type: "link",
    icon: <EditIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link
  },
  {
    key: "connections",
    name: t('sidebar.connections'),
    href: "account/connections",
    type: "link",
    icon: <LinkIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link,
    isNew: true
  },
  {
    key: "badges",
    name: t('sidebar.badges'),
    href: "account/badges",
    type: "link",
    icon: <VerifiedIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link,
    isNew: true
  },
  {
    key: "profile-types",
    name: t('sidebar.profile-types'),
    href: "account/profile-types",
    type: "link",
    icon: <GameControlIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link
  },
  {
    key: "socials",
    name: t('sidebar.socials'),
    href: "account/socials",
    type: "link",
    icon: <SocialIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link
  },
  // {
  //   key: "company",
  //   name: t('sidebar.create-company'),
  //   href: "company",
  //   type: "link",
  //   icon: <BarChartIcon className="group-hover:text-blue text-xl" />,
  //   linkComponent: Link,
  //   isNew: true
  // },
  {
    key: "my-link",
    name: t('sidebar.my-link'),
    href: "account/my-link",
    type: "link",
    icon: <StarsIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link
  },
  {
    key: "divider",
    name: "Divider",
    href: "#",
    type: "divider",
  },
  {
    key: "logout",
    name: t('sidebar.logout'),
    href: "auth/logout",
    type: "link",
    icon: <LogoutIcon className="group-hover:text-blue text-xl" />,
    linkComponent: Link
  }
]

export const navbarItems = ({ t, router, locale, username }: {
  t: any,
  router: any,
  locale: string,
  username: string
}) => [
  {
    key: '1',
    label: t('navbar.profile'),
    danger: true,
    icon: <PersonIcon className="text-base w-5 h-5 flex items-center justify-center" />,
    onClick: () => router.push(`/${locale}/${username}`),
    className: 'text-sm gap-2 text-white-300 hover:text-white-100',
  },
  {
    key: '2',
    label: t('navbar.editor'),
    danger: true,
    icon: <EditIcon className="text-base w-5 h-5 flex items-center justify-center" />,
    onClick: () => router.push(`/${locale}/editor`),
    className: 'text-sm gap-2 text-white-300 hover:text-white-100',
  },
  // {
  //   key: '2',
  //   label: t('navbar.create-company'),
  //   danger: true,
  //   icon: <BarChartIcon className="text-base w-5 h-5 flex items-center justify-center" />,
  //   onClick: () => router.push(`/${locale}/company`),
  //   className: 'text-sm gap-2 text-white-300 hover:text-white-100',
  // },
  {
    key: '3',
    label: t('navbar.settings'),
    danger: true,
    icon: <SettingsIcon className="text-base w-5 h-5 flex items-center justify-center" />,
    onClick: () => router.push(`/${locale}/account`),
    className: 'text-sm gap-2 text-white-300 hover:text-white-100',
  },
  {
    key: '4',
    label: t('navbar.logout'),
    icon: <LogoutIcon className="text-base w-5 h-5 flex items-center justify-center" />,
    onClick: () => logout({ t }),
    className: 'text-sm gap-2 text-white-300 hover:text-white-100',
  }
]