import React, { FC, useEffect, useRef, useState } from "react";
import move from '@apg.gg/core/lib/move';
import { motion } from 'framer-motion';
import classNames from 'classnames';

export interface CardStackProps<T> {
  cards: T[];
  className?: string;
  position?: 'right' | 'left' | 'top' | 'bottom';
  renderCard: (item: T, index: number) => JSX.Element;
}

const CardStack: FC<CardStackProps<any>> = ({ cards, className = '', position = 'right', renderCard })=> {
  const [stack, setCards] = useState(cards);

  const constraintsRef = useRef(null);

  const CARD_OFFSET = 45;
  const SCALE_FACTOR = 0.05;

  const moveToEnd = (from: number) => {
    setCards(move({ array: stack, moveIndex: from, toIndex: stack.length - 1 }));
  };

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    intervalIdRef.current = setInterval(() => {
      moveToEnd(0);
    }, 4000); // every 4 seconds

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack]);

  return (
    <div
      ref={constraintsRef}
      className={classNames(
        className,
        'relative max-w-full w-[650px] h-[520px] mx-auto'
      )}
    >
      {stack.map((item, index) => {
        const canDrag = index === 0;

        let styleDrag: { x?: number; y?: number; } = { x: 0 };
        let animatePosition: { right?: number; left?: number | string; top?: number | string; bottom?: number; } = { right: (index * -CARD_OFFSET) + 290 };

        switch (position) {
          case 'left':
            styleDrag = { x: 0 };
            animatePosition = { left: (index * -CARD_OFFSET) + 250, top: '50%' };
            break;
          case 'top':
            styleDrag = { y: 0 };
            animatePosition = { top: (index *  -25) + 70, left: '50%' };
            break;
          case 'bottom':
            styleDrag = { y: 0 };
            animatePosition = { bottom: (index *  -25) + 70, left: '50%' };
            break;
          default: // 'right'
            styleDrag = { x: 0 };
            animatePosition = { right: (index * -CARD_OFFSET) + 250, top: '50%' };
            break;
        }

        return (
          <motion.div
            key={`cardstack-item-${item.id}`}
            initial={false}
            className="absolute max-w-[348px] w-[348px] h-[432px] cursor-grab shadow-xl rounded-2xl"
            style={{
              transformOrigin: "left center",
              filter: index !== 0 ? "blur(2px)" : "none",
              ...styleDrag
            }}
            animate={{
              x: index * 10,
              translateX: position === 'top' || position === 'bottom' ? '-50%' : 0,
              translateY: position === 'left' || position === 'right' ? '-50%' : 0,
              ...animatePosition,
              scale: 1 - index * SCALE_FACTOR,
              zIndex: stack.length - index
            }}
            drag={canDrag ? "x" : false}
            dragConstraints={constraintsRef}
            dragElastic={0.1}
            dragMomentum={false}
            onDragEnd={() => moveToEnd(index)}
          >
            {renderCard(item, index)}
          </motion.div>
        );
      })}
    </div>
  );
};

export default CardStack;
