import '@splidejs/splide/dist/css/splide.min.css';
import { UsersProfileTypeElement } from "@/domain/general.interface";
import Link from "next/link";
import { FC } from "react";
import CardProfile from '@apg.gg/core/lib/CardProfile';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next-nprogress-bar';
import { useParams } from 'next/navigation';
import useAuthStore from '@/store/auth.store';
import { CardTagProps } from '@apg.gg/core/lib/CardBase';
import Tag from '@apg.gg/core/lib/Tag';
import { cn } from '@apg.gg/core/lib/cn';
import FollowButton from '@/components/FollowButton';

interface CardProfileFollowProps {
  user: UsersProfileTypeElement;
  className?: string;
}

const CardProfileFollow: FC<CardProfileFollowProps> = ({ user, className = '' }) => {
  const t = useTranslations('Profile');
  const router = useRouter();
  const { locale } = useParams();
  const { userData } = useAuthStore();

  return (
    <div
      className={cn(
        "flex justify-center cursor-pointer overflow-hidden",
        className
      )}
      role='button'
      onClick={() => router.push(`/${locale}/${user.username}`)}
    >
      <CardProfile 
        {...user}
        link={`/${locale}/${user.username}`}
        key={`card-profile-${user.id}`}
        linkComponent={Link}
        locale={locale}
        translationObject={{
          thisAccountIsVerified: t('this-account-is-verified'),
          thisAccountIsBetaTester: t('this-account-is-beta-tester'),
          aboutMe: t('section-about-title')
        }}
        className=''
        tags={
          <>
            {user.tags?.map((tag: CardTagProps) => (
              <button
                key={`${user.id}-${tag.title}`}
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`/${locale}/${tag.link}`)
                }}
              >
                <Tag
                  icon={tag.icon}
                  className="max-w-none"
                  type={tag.type}
                >
                  {tag.title}
                </Tag>
              </button>
            ))}
          </>
        }
        actions={
          <>
            {user.username !== userData?.username ? (
              <FollowButton 
                isFollowing={user.isFollowing}
                username={user.username}
                followingId={user.id}
              />
            ) : null}
          </>
        }
      />
    </div>
  )
}

export default CardProfileFollow