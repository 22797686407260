"use client";

import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { cn } from "@apg.gg/core/lib/cn";
import Spinner from "@/components/ui/Spinner";
import { Event } from "@/domain/account/events.interface";
import { EventsEndpoints } from "@/graph/event/endpoints";
import useWindowSize from "@apg.gg/core/lib/useWindowSize";
import CardEvent from "@apg.gg/core/lib/CardEvent";
import Link from "next/link"
import CardStack from "@/components/CardStack";
import { useParams } from "next/navigation";

export default function SectionEventsClient() {
  const { locale } = useParams();
  const { isLtMd } = useWindowSize();
  const t = useTranslations('Home');

  const { data: events, isLoading } = useQuery({
    queryKey: ['apg-home-events-section'],
    queryFn: async () => await EventsEndpoints.getFeaturedEvents({
      limit: 5
    })
  })

  return (
    <section id="events" className="w-full py-8 relative">
      <div className="container-home">
        {isLoading ? (
          <div className={cn(
            "flex items-center justify-center w-full h-96"
          )}>
            <Spinner />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col items-center gap-4 justify-center px-10">
              <h1 className="uppercase text-headline-lg sm:text-display-sm text-center md:text-right max-w-5xl text-balance">{t("share-your-talent-and-reach-new-goals")}</h1>
              <p className="text-2xl font-source-sans-pro text-center md:text-right text-balance">
                {t("at-apg-share-your-achievements-events-and-news-connect-with-partners-sponsors-and-other-gamers-expand-your-network-and-reach-new-goals-shine-as-a-gamer-at-apg")}
              </p>
            </div>
            <div className="flex relative z-10 overflow-hidden items-center justify-end">
              <CardStack 
                cards={events as Event[]} 
                position={isLtMd ? "bottom" : "right"}
                renderCard={(event: Event) => 
                  <CardEvent
                    className="bg-black"
                    id={event.id}
                    title={event.title}
                    slug={event.slug}
                    owner={event.owner}
                    banner={event.image}
                    link={event.link || undefined}
                    shortDescription={""}
                    shortRichDescription={event.description}
                    date={new Date(event.date).toISOString()}
                    game={event.game ? event.game.name : 'General'}
                    profileType={event.profileType}
                    type={event.eventType.name}
                    tag={event.eventTag.name}
                    category={event.eventCategory.name}
                    locale={locale}
                    linkComponent={Link}
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}