import { apgGraphQL } from "../apgApi"
import { operationProfileEvent } from "../graph-queries";
import { RequestError } from "../general.endpoints";
import { InfinitePaginationResponse, PaginationResponse } from "@/domain/general.interface";
import { SelectOption } from '@apg.gg/core/lib/Select';
import { convertEventTypesToOptions, mapUsersToCards } from "@/lib/utils/generals";
import { Event } from "@/domain/account/events.interface";
import { operationEvents } from "./queries";
import { CardProfileProps } from "@apg.gg/core/lib/CardProfile";
import { operationAdsHome } from "../advertising/queries";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token?: string;
  data: T;
}

const getEventTypes = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileEvent,
    'GetEventTypes'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertEventTypesToOptions(data.eventTypes);
}

const getEventTags = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationEvents,
    'GetEventTags'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertEventTypesToOptions(data.eventTags);
}

const getEventCategories = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationEvents,
    'GetEventGategories'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertEventTypesToOptions(data.eventGategories);
}

const getFeaturedEvents = async <T>({ limit }: { limit: number }): Promise<T[]> => {
  const { data, errors } = await apgGraphQL(
    operationAdsHome,
    'GetHomeEventFeatures',
    {
      "limit": limit
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const events = data.events.map((event: any) => event.event);

  return events;
}

const getEvents = async <T>({
  limit = 12,
  page = 1,
  title,
  date,
  eventTypeId,
  eventCategoryId,
  eventTagId,
  gameId,
}: any): Promise<InfinitePaginationResponse<T>> => {
  const offset = (page - 1) * limit;

  const { data, errors } = await apgGraphQL(
    operationEvents,
    'GetEvents',
    {
      "where": {
        "isActive": { "_eq": true },
        ...title ? { "title": { "_ilike": `%${title}%` } } : {},
        ...date ? { "date": date } : {},
        ...eventTypeId ? { "eventTypeId": { "_eq": eventTypeId } } : {},
        ...eventCategoryId ? { "eventCategoryId": { "_eq": eventCategoryId } } : {},
        ...eventTagId ? { "eventTagId": { "_eq": eventTagId } } : {},
        ...gameId ? { "gameId": { "_eq": gameId } } : {}
      },
      "limit": limit,
      "offset": offset
    }
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.events,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getEventBySlug = async ({
  slug,
  userId = 0
}: {
  slug: string
  userId?: number
}): Promise<Event> => {
  const { data, errors } = await apgGraphQL(
    operationEvents,
    'GetEventBySlug',
    {
      "slug": slug,
      "userId": userId
    }
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.events[0];
}

const getEventParticipants = async ({
  limit = 12,
  page = 1,
  slug,
  userId
}: any): Promise<InfinitePaginationResponse<CardProfileProps>> => {
  const offset = (page - 1) * limit;

  const { data, errors } = await apgGraphQL(
    operationEvents,
    'GetEventParticipants',
    {
      "slug": slug,
      "limit": limit,
      "offset": offset,
      "userId": userId
    }
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const participants = data.participants.map((participant: any) => participant.user);

  return {
    items: mapUsersToCards(participants, []),
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const get = async <T>({
  userId,
  token,
  limit = 4,
  page = 1,
  isEditing = false
}: any): Promise<PaginationResponse<T>> => {
  const offset = (page - 1) * limit;
  const showOnlyActive = !isEditing ? { "isActive": { "_eq": true } } : {};

  const { data, errors } = await apgGraphQL(
    operationProfileEvent,
    'GetUserEvents',
    {
      "where": {
        "userId": { "_eq": userId },
        ...showOnlyActive
      },
      "limit": limit,
      "offset": offset
    },
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userEvents,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
    hasPreviousPage: page > 1,
    hasNextPage: page < totalPages
  };
}

const getInfinite = async <T>({
  userId,
  token,
  limit = 4,
  page = 1,
  isEditing = false
}: any): Promise<InfinitePaginationResponse<T>> => {
  const offset = (page - 1) * limit;
  const showOnlyActive = !isEditing ? { "isActive": { "_eq": true } } : {};

  const { data, errors } = await apgGraphQL(
    operationProfileEvent,
    'GetUserEvents',
    {
      "where": {
        "userId": { "_eq": userId },
        ...showOnlyActive
      },
      "limit": limit,
      "offset": offset
    },
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userEvents,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  };
}

const insert = async ({ data: event, userId, token }: EditProfileSetProps<Partial<Event>>): Promise<Event> => {
  const { data: { insertEvent }, errors } = await apgGraphQL(
    operationProfileEvent,
    'InsertEvent',
    {
      "object": event
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return insertEvent;
}

const update = async ({ data: event, token }: EditProfileSetProps<Partial<Event>>): Promise<Event> => {

  const { data, errors } = await apgGraphQL(
    operationProfileEvent,
    'UpdateEvent',
    {
      "_set": event,
      "id": event.id,
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updateEvent;
}

const remove = async ({ eventId, token }: any): Promise<boolean> => {
  const { errors } = await apgGraphQL(
    operationProfileEvent,
    'DeleteUserEvent',
    {
      "eventId": eventId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return true;
}

const joinEvent = async ({ eventId, token }: any): Promise<boolean> => {
  const { errors } = await apgGraphQL(
    operationEvents,
    'JoinEvent',
    {
      "object": {
        "eventId": eventId
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return true;
}

export const EventsEndpoints = {
  getEventTypes,
  getEventTags,
  getEventCategories,
  getFeaturedEvents,
  getEvents,
  getEventBySlug,
  getEventParticipants,
  get,
  getInfinite,
  insert,
  update,
  remove,
  joinEvent
}
