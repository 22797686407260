"use client";

import { useQuery } from "@tanstack/react-query";
import { GeneralEndpoints } from "@/graph/general.endpoints";
import { useTranslations } from "next-intl";
import CardProfileFollow from "@/components/ui/CardProfileFollow";
import logError from "@/lib/utils/logError";
import { ErrorBoundary } from "react-error-boundary";
import ErrorCardProfileFallback from "../ErrorCardProfile";
import { UsersProfileTypeElement } from "@/domain/general.interface";
import { cn } from "@apg.gg/core/lib/cn";
import Spinner from "@/components/ui/Spinner";
import Marquee from "react-fast-marquee";
import { useRouter } from "next-nprogress-bar";
import Button from "@apg.gg/core/lib/Button";
import { useParams } from "next/navigation";

export default function LatestUsersClient({
  userId
}: {
  userId: number;
}) {
  const router = useRouter();
  const { locale } = useParams();
  const t = useTranslations('Home');

  const { data: users, isLoading } = useQuery({
    queryKey: ['apg-home-latest-users'],
    queryFn: async () => await GeneralEndpoints.getUsersMarquee({
      userLoggedId: userId,
      limit: 8
    })
  })

  return (
    <section id="gamers" className="w-full py-32">
      <div className="w-full">
        <div className="flex flex-col items-center gap-4 justify-center">
          <h1 className="uppercase text-headline-lg sm:text-display-sm px-0 sm:px-10 text-center max-w-5xl">{t("join-the-apg-community-and-experience-the-ultimate-gaming-experience")}</h1>
          <p className="text-2xl font-source-sans-pro text-center">{t("register-now-and-start-exploring-all-that-apg-has-to-offer")}</p>
        </div>

        {isLoading ? (
          <div className={cn(
            "flex items-center justify-center w-full h-96"
          )}>
            <Spinner />
          </div>
        ) : (
          <div className="relative z-10 overflow-hidden">
            <Marquee
              className="my-8"
              pauseOnHover={true}
            >
              {users?.map((user: UsersProfileTypeElement, index: number) => {
                return (
                  <ErrorBoundary
                    key={`profile-type-${user.id}-${index}`}
                    FallbackComponent={({ error }) => (
                      <ErrorCardProfileFallback error={error} section="home-slider-card-profile" />
                    )}
                    onError={logError}
                  >
                    <CardProfileFollow user={user} className="w-[348px] mr-4" />
                  </ErrorBoundary>
                )
              })}
            </Marquee>
          </div>
        )}

        <div className="flex flex-col items-center gap-4 justify-center">
          <Button
            type="primary"
            className="px-8 py-4 text-headline-sm"
            onClick={() => {
              router.push(`/${locale}/auth/login`);
            }}
          >
            {t('join-our-community')}
          </Button>
        </div>
      </div>
    </section>
  )
}