type User = {
  username: string;
  id: number;
  profileImage: string;
  name: string;
};

type Game = {
  game: {
    users: {
      user: User;
    }[];
  }
};

type ProfileType = {
  user_profile_types: {
    user: User;
  }[];
};

type Data = {
  games: Game[];
  profileTypes: {
    profileType: ProfileType;
  }[];
};

export const extractUniqueUsers = (data: Data): User[] => {
  const userMap: Map<number, User> = new Map();

  // Extract users from games
  data.games.forEach((games) => {
    const { game } = games;
    game.users.forEach(({ user }) => {
      if (!userMap.has(user.id)) {
        userMap.set(user.id, user);
      }
    });
  });

  // Extract users from profileTypes
  data.profileTypes.forEach(({ profileType }) => {
    profileType.user_profile_types.forEach(({ user }) => {
      if (!userMap.has(user.id)) {
        userMap.set(user.id, user);
      }
    });
  });

  // Return an array of unique users
  return Array.from(userMap.values());
};